import React from 'react';
import ChatRoute from './components/routes/ChatRoute';
import Login from './components/Login';
import ProtectedRoute from './components/routes/ProtectedRoute';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { MessagesProvider } from './contexts/MessageProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardRoute from './components/routes/DashboardRoute';


function App() {
  return (
    <AuthProvider>
      <MessagesProvider>
        <Router>
          <div>
            <Routes>
              <Route path="/login" element={<Login />} />
              
              <Route element={<ProtectedRoute allowedRoles={['ROLE_USER', 'ROLE_ADMIN']} />}>
                <Route path="/chat/new" element={<ChatRoute />} />
                <Route path="/chat/:chatId" element={<ChatRoute />} />
              </Route>

              <Route element={<ProtectedRoute allowedRoles={['ROLE_MANAGER', 'ROLE_ADMIN']} />}>
                <Route path="/dashboard" element={<DashboardRoute />} />
              </Route>

              <Route path="/" element={<Navigate to="/login" />} />
              
              <Route path="/unauthorized" element={<div>Unauthorized Access</div>} />
            </Routes>
          </div>
        </Router>
      </MessagesProvider>
    </AuthProvider>
  );
}

export default App;
