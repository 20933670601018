const LocalStorage = () => {
    const CHATS = "chats";

    const persistMessage = (message, chatId, title, error, context, userRole) => {
        let storedChat = JSON.parse(localStorage.getItem(CHATS)) || {};

        if (!storedChat[chatId]) {
            storedChat[chatId] = {
                date: new Date(), 
                messages: [],  
                title: title,
                error: error,
                context: context,
                userRole: userRole                
            };
        } 

        storedChat[chatId].error = error;
        storedChat[chatId].messages.push(message);
        
        localStorage.setItem(CHATS, JSON.stringify(storedChat));
    };

    const getMessages = (chatId) => {
        const storedChat = JSON.parse(localStorage.getItem(CHATS)) || {};
        return storedChat[chatId] ? storedChat[chatId].messages : [];
    };

    const getTitle = (chatId) => {
        const storedChat = JSON.parse(localStorage.getItem(CHATS)) || {};
        return storedChat[chatId] ? storedChat[chatId].title : [];
    };

    const getContextValue = (chatId) => {
        const storedChat = JSON.parse(localStorage.getItem(CHATS)) || {};
        return storedChat[chatId] ? storedChat[chatId].context : [];
    };

    const getChatDate = (chatId) => {
        const storedChat = JSON.parse(localStorage.getItem(CHATS)) || {};
        return storedChat[chatId] ? storedChat[chatId].date : null; 
    };

    const hasError = (chatId) => {
        const storedChat = JSON.parse(localStorage.getItem(CHATS)) || {};
        return storedChat[chatId] ? storedChat[chatId].error : null; 
    }

    const getChats = (userRole) => {
        const storedChat = JSON.parse(localStorage.getItem(CHATS)) || {};
        return Object.keys(storedChat).map(chatId => ({
            id: chatId,
            date: storedChat[chatId].date,
            messages: storedChat[chatId].messages,
            title: storedChat[chatId].title,
            error: storedChat[chatId].error,
            context: storedChat[chatId].context,
            userRole: storedChat[chatId].userRole,
        }))
        .filter(chat => chat.userRole === userRole)
        .filter(chat => chat.messages && chat.messages.length >= 2)
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    };

    return { persistMessage, getMessages, getChatDate, getChats, getTitle, getContextValue, hasError };
};

export default LocalStorage;
