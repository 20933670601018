import React, { createContext, useContext, useState } from 'react';

const MessagesContext = createContext();

export const MessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  function updateMessages(messagesList, loadChat = false) {
    setMessages((prevMessages) => {
      if(loadChat){
        return messagesList;

      } else {
        let filteredMessages = prevMessages.filter(message => message.sender !== "system");
        return [...filteredMessages, ...messagesList];

      }

    });
  }
  

  return (
    <MessagesContext.Provider value={{ messages, updateMessages, setMessages }}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
