import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

import './ChatHistory.css'; 

function ChatHistory({ chat }) {
    const title = chat.title + (chat.messages[1] ? 
        (chat.messages[1].text.length > 60 ? 
            ': ' + chat.messages[1].text.slice(0, 57) + '...' : 
            ': ' + chat.messages[1].text) : '');

    return (
        <div className="chat-history-container">
            <CircleIcon className={chat.error? 'chat-history-status-error me-2' : 'chat-history-status-success me-2'} />
            <div className="chat-history-title">{title}</div>
        </div>
    );
}

export default ChatHistory;
