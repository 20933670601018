import axios from 'axios';
import { useCallback } from 'react';

const ChatApi = (token) => {

  const ENDPOINT = process.env.REACT_APP_API_GENERATE_ENDPOINT;

  const sendPrompt = useCallback(async (message) => {
    try {
      const response = await axios.post(ENDPOINT, {
        query: message
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const { object } = response.data;
      const parsedObject = JSON.parse(object);
      return parsedObject.response;

    } catch (error) {
      console.error('Error in API call:', error);
      throw error;
    }
  }, [token, ENDPOINT]);

  return { sendPrompt };
};

export default ChatApi;
