import React from "react";
import "./Message.css";
import ChatIcon from "./ChatIcon";
import haisLogo from "../images/logo.svg";
import userLogo from "../images/medico.png";
import ReactMarkdown from "react-markdown";

function Message({ text, sender }) {
  const isUser = sender === "user";

  return (
    <div
      className={`chat-item ${isUser ? "user-chat-item" : "assistant-chat-item"}`}
    >
      <div className={`message ${isUser ? "" : "assistant-message"}`}>
        {isUser ? (
          <>
            <div>{text}</div>
            <div className="chat-icon"><ChatIcon src={userLogo} alt="Ícone do usuário" user={true} /></div>
          </>
        ) : (
          <>
            <div className="chat-icon"><ChatIcon src={haisLogo} alt="Ícone do assistente" user={false} /></div>
            <div className="assistant-text-message"><ReactMarkdown>{text}</ReactMarkdown></div>
          </>
        )}
      </div>
    </div>
  );
}

export default Message;