import React, { useRef, useEffect, useState, useCallback } from "react";
import { useMessages } from "../contexts/MessageProvider";
import { useAuth } from "../contexts/AuthProvider";
import ChatApi from "../services/chatAPIService";
import LocalStorage from "../services/localStorageService";

import Message from "./Message";
import InputBox from "./InputBox";
import SuggestionButton from "./SuggestionButton";

import "./ChatWindow.css";
import logo from "../images/logo.svg";

const MemoizedMessage = React.memo(Message);
const MemoizedSuggestionButton = React.memo(SuggestionButton);
const MemoizedInputBox = React.memo(InputBox);

function ChatWindow({ chatId }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const RESUMO_PACIENTE_CONTEXT = ["resumo_paciente", "Resumo do Paciente"];
  const GESTAO_LEITOS_CONTEXT = ["gestao_leitos", "Gestão de Leitos"];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const TAXA_OCUPACAO_CONTEXT = ["taxa_ocupacao_leitos", "Taxa de Ocupação de Leitos"];
  const DUVIDAS_GERAIS = ["duvidas_gerais", "Dúvidas Gerais"];
  const RESUMO_PACIENTE_ERROR_MSG = "Por favor, envie um número válido.";

  const chatWindowRef = useRef(null);

  const { messages, updateMessages } = useMessages();
  const { sendPrompt } = ChatApi(useAuth().userToken);
  const { persistMessage, getMessages, getTitle, getContextValue, hasError } = LocalStorage();
  const { userRole } = useAuth();
  
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [selectedContextValue, setSelectedContextValue] = useState("");
  const [selectedContext, setSelectedContext] = useState("");
  // eslint-disable-next-line
  const [newChatId, setNewChatId] = useState(chatId || "");  
  const [hideInput, setHideInput] = useState(false);

  const scrollToBottom = useCallback(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (!newChatId) {
      const generatedId = chatId || Math.floor(Math.random() * 10000000) + 1;
      setNewChatId(generatedId); 
    }
  }, [chatId, newChatId]);

  const persistChatMessages = useCallback(
    (messagesList, selectedContext, selectedContextValue, error = false) => {
      
      if(selectedContextValue === RESUMO_PACIENTE_CONTEXT[0] 
        && messagesList[0].text === RESUMO_PACIENTE_ERROR_MSG){
          error = true;
      }

      const id = newChatId;  
      updateMessages(messagesList);

      messagesList.forEach((message) => {
        if (message.sender !== "system") {
          persistMessage(message, id, selectedContext, error, selectedContextValue, userRole);
        }
      });
    },
    [RESUMO_PACIENTE_CONTEXT, newChatId, updateMessages, persistMessage, userRole]
  );

  const handleChatSubmit = useCallback(
    async (message, context, contextValue) => {
      if (loadingAnswer) return;
      setLoadingAnswer(true);

      context = selectedContext || context;
      contextValue = selectedContextValue || contextValue;
      let prompt = "";

      if(contextValue === TAXA_OCUPACAO_CONTEXT[0]){
        persistChatMessages([
          { text: "...", sender: "system" },
        ], context, contextValue);
        prompt = message;

      } else {
        persistChatMessages([
          { text: message, sender: "user" },
          { text: "...", sender: "system" },
        ], context, contextValue);
        prompt = contextValue + "#" + message;
      }

      try {
        const chatResponse = await sendPrompt(prompt);
        persistChatMessages([{ text: chatResponse, sender: "chat" }], context, contextValue);

      } catch (error) {
        console.error("Error while fetching chat response:", error);
        persistChatMessages(
          [
            {
              text: "Ocorreu um erro. Por favor, tente novamente.",
              sender: "chat",
            },
          ],
          context,
          contextValue,
          true
        );
      } finally {
        setLoadingAnswer(false);
      }
    },
    [loadingAnswer, selectedContext, selectedContextValue, TAXA_OCUPACAO_CONTEXT, persistChatMessages, sendPrompt]
  );

  const initSuggestionChat = useCallback(
    async (context, contextValue, message) => {
      setSelectedContext(context);
      setSelectedContextValue(contextValue);

      persistChatMessages([{ text: message, sender: "chat" }], context, contextValue);
      setLoadingAnswer(false);
      
      if(contextValue === TAXA_OCUPACAO_CONTEXT[0]){
        await handleChatSubmit(TAXA_OCUPACAO_CONTEXT[0] + "#", context, contextValue);
      }
    },
    [persistChatMessages, handleChatSubmit, TAXA_OCUPACAO_CONTEXT] 
  );

  const canHideInput = useCallback(
    () => {
      if(selectedContextValue === TAXA_OCUPACAO_CONTEXT[0]){
        return true;
      } else if (selectedContextValue === RESUMO_PACIENTE_CONTEXT[0] && messages.length > 2){
        if(hasError(newChatId)){
          return false 
        } else {
          return true;
        }
      }
      return false;
    },
    [selectedContextValue, TAXA_OCUPACAO_CONTEXT, RESUMO_PACIENTE_CONTEXT, messages.length, hasError, newChatId]
  )

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    setHideInput(canHideInput());
  }, [canHideInput]);

  useEffect(() => {
    if (chatId !== undefined && messages.length === 0) {
      const storedMessages = getMessages(chatId);
      if (storedMessages) {
        updateMessages(storedMessages, true);
        setSelectedContext(getTitle(chatId));
        setSelectedContextValue(getContextValue(chatId));
      }
    }
    setLoadingMessages(false);
  }, [chatId, messages.length, updateMessages, getMessages, getTitle, getContextValue]);

  if (loadingMessages) {
    return null;
  }

  if (messages.length === 0) {
    return (
      <div className="chat-main-content">
        <div className="chat-window empty-chat">
          <div className="title-content mb-3">
            <img src={logo} alt="Assistant Logo" className="assistant-logo" />
            <h2 className="assistant-name">Assistente Virtual Hospitalar</h2>
            <h4 className="assistant-sub-name">
              Escolha o prompt inicial para dar inicio ao chat
            </h4>
          </div>
          <div className="suggestions">
            <MemoizedSuggestionButton
              context={GESTAO_LEITOS_CONTEXT[1]}
              contextValue={GESTAO_LEITOS_CONTEXT[0]}
              message={
                "Você selecionou **Análise de Leitos**. Envie as informações do paciente para darmos inicio a conversa."
              }
              initSuggestionChat={initSuggestionChat}
            />
            <MemoizedSuggestionButton
              context={RESUMO_PACIENTE_CONTEXT[1]}
              contextValue={RESUMO_PACIENTE_CONTEXT[0]}
              message={
                "Você selecionou **Resumo do Paciente**. Envie o ID do paciente para darmos inicio a conversa."
              }
              initSuggestionChat={initSuggestionChat}
            />
            <MemoizedSuggestionButton
              context={TAXA_OCUPACAO_CONTEXT[1]}
              contextValue={TAXA_OCUPACAO_CONTEXT[0]}
              message={"Você selecionou **Taxa de Ocupação de Leitos**."}
              initSuggestionChat={initSuggestionChat}
            />
            <MemoizedSuggestionButton
              context={DUVIDAS_GERAIS[1]}
              contextValue={DUVIDAS_GERAIS[0]}
              message={
                "Você selecionou **Dúvidas Gerais**. Envie seu questionamento para darmos inicio a conversa."
              }
              initSuggestionChat={initSuggestionChat}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-main-content">
      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((message, index) => (
          <MemoizedMessage
            key={index}
            text={message.text}
            sender={message.sender}
          />
        ))}
      </div>
      {!hideInput && (
        <MemoizedInputBox
          loadingAnswer={loadingAnswer}
          handleChatSubmit={handleChatSubmit}
        />
      )}
    </div>
  );
}

export default ChatWindow;
